import { template as template_c0d987f43a4b40db81cb5a2ad90b5fec } from "@ember/template-compiler";
const SidebarSectionMessage = template_c0d987f43a4b40db81cb5a2ad90b5fec(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
