import { template as template_86a07dead5c641ccb631f32a7c5968b5 } from "@ember/template-compiler";
const WelcomeHeader = template_86a07dead5c641ccb631f32a7c5968b5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
